import { useState, useEffect } from "react";
import config from '../../config/config';
import { Outlet } from "react-router-dom";

import { updateUser, getAuth, authHeader } from '../../utils/auth';

// tour
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS, LIFECYCLE, CallBackProps } from 'react-joyride';

import Sidebar from "../Sidebar/Sidebar";
import SidebarMobile from "../Sidebar/SidebarMobile";
import Header from "../Header/Header";

const Main = ({backEnabled, backEvent, setBackEvent}) => {
    const SUPPORT_CODE = 'window.$crisp=[];window.CRISP_WEBSITE_ID="f44db116-10dd-42e1-9c93-56fd560f2f06";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';

    // insert feedback form on page load
    useEffect(() => {
        const script = document.createElement("script");
        script.innerHTML = SUPPORT_CODE;
        document.body.appendChild(script
        );
    }, []);

    // sidebar toggling
    const [toggled, setToggled] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        if (width <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [width]);

    // disable joyride on mobile
    useEffect(() => {
        if(isMobile) {
            setSteps(config.joyride.steps_mobile);
            setJoyrideRunning(true);
        } else {
            setSteps(config.joyride.steps);
            setJoyrideRunning(true);
        }
    }, [isMobile]);

    // get some info to know what joyride steps to show users
    const [joyrideRunning, setJoyrideRunning] = useState(false);
    const [joyrideActive, setJoyrideActive] = useState(false);
    const [steps, setSteps] = useState(config.joyride.steps);
    const [stepIndex, setStepIndex] = useState(0);

    const [hasNetizens, setHasNetizens] = useState(null);
    const [hasPipelines, setHasPipelines] = useState(null);
    const [hasAccounts, setHasAccounts] = useState(null);
    const [hasSchedule, setHasSchedule] = useState(null);

    useEffect(() => {
        setJoyrideRunning(true);
    }, []);

    useEffect(() => {
        checkTourStatus();
    }, []);

    const checkTourStatus = async () => {
        const user = await updateUser();
        console.log('user', user);
        const tour = user?.tour;
        console.log('checking tour status', tour);
        if(tour) {
            setHasNetizens(tour?.netizens===true);
            setHasPipelines(tour?.pipelines===true);
            setHasAccounts(tour?.accounts===true);
            setHasSchedule(tour?.schedules===true);
        } else {
            console.log('no tour data... assume user hasn\'t done tour');
            setHasNetizens(false);
            setHasPipelines(false);
            setHasAccounts(false);
            setHasSchedule(false);
        }

        // if a user has been loaded, but tour has not been completed. we may want to force them to certain pages
        if(user && window.location.pathname !== '/settings' && window.location.pathname !== '/feedback') {
            if((!tour || !tour.netizens) && window.location.pathname !== '/netizens' && !window.location.pathname.includes('oauth2')) {
                console.log('forcing user to netizens page');
                window.location.href = '/netizens';
            }

            // if they've made a netizen, but no accounts, force them to accounts
            if(tour && tour.netizens && !tour.accounts && window.location.pathname !== '/accounts' && !window.location.pathname.includes('oauth2')) {
                console.log('forcing user to accounts page');
                window.location.href = '/accounts';
            }
        }
    }

    // if any of the steps change, update the steps
    useEffect(() => {
        let newJoyrideActive = true;
        let newJoyrideRunning = true;

        if(isMobile) {
            if(hasNetizens===false) {
                setStepIndex(Math.max(stepIndex, 0));
            } else if(hasAccounts===false) {
                setStepIndex(Math.max(stepIndex, 2));
            } else if(hasPipelines===false) {
                setStepIndex(Math.max(stepIndex, 4));
            } else if(hasSchedule===false) {
                setStepIndex(Math.max(stepIndex, 6));
            } else {
                newJoyrideActive = false;
                newJoyrideRunning = false;
            }
        } else {
            if(hasNetizens===false) {
                setStepIndex(Math.max(stepIndex, 0));
            } else if(hasAccounts===false) {
                setStepIndex(Math.max(stepIndex, 1));
            } else if(hasPipelines===false) {
                setStepIndex(Math.max(stepIndex, 2));
            } else if(hasSchedule===false) {
                setStepIndex(Math.max(stepIndex, 3));
            } else {
                newJoyrideActive = false;
                newJoyrideRunning = false;
            }
        }

        setJoyrideActive(newJoyrideActive);
        setJoyrideRunning(newJoyrideRunning);
    }, [stepIndex, hasNetizens, hasPipelines, hasAccounts, hasSchedule, isMobile]);

    // callback
    const handleJoyrideCallback = (data) => {
        const { action, index, type, status, lifecycle } = data;
        
        if (action === ACTIONS.CLOSE && lifecycle === LIFECYCLE.COMPLETE) {
            if(isMobile) {
                if(stepIndex===0 || stepIndex===2 || stepIndex===4 || stepIndex===6) {
                    toggleMenuEvent();
                } else {
                    checkTourStatus();

                    // go to the page requested, if they're not there already (and toggle menu)
                    if(stepIndex===1 && window.location.pathname !== '/netizens') {
                        window.location.href = '/netizens';
                    } else if(stepIndex===3 && window.location.pathname !== '/accounts') {
                        window.location.href = '/accounts';
                    } else if(stepIndex===5 && window.location.pathname !== '/pipelines') {
                        window.location.href = '/pipelines';
                    } else if(stepIndex===7 && window.location.pathname !== '/schedules') {
                        window.location.href = '/schedules';
                    } else {
                        toggleMenuEvent();
                    }
                }
            } else {
                checkTourStatus();

                // go to the page requested, if they're not there already (and toggle menu)
                if(stepIndex===0 && window.location.pathname !== '/netizens') {
                    window.location.href = '/netizens';
                } else if(stepIndex===1 && window.location.pathname !== '/accounts') {
                    window.location.href = '/accounts';
                } else if(stepIndex===2 && window.location.pathname !== '/pipelines') {
                    window.location.href = '/pipelines';
                } else if(stepIndex===3 && window.location.pathname !== '/schedules') {
                    window.location.href = '/schedules';
                }
            }
        }
    }

    const toggleMenuEvent = () => {
        // if this is toggle from false to true, and the tour stepIndex is not at the end, increment the stepIndex
        if(!toggled && [0, 2, 4, 6].includes(stepIndex)) {
            setStepIndex(stepIndex+1);
        }

        if(toggled && [1, 3, 5, 7].includes(stepIndex)) {
            setStepIndex(stepIndex-1);
        }   

        setToggled(!toggled);
    }

    return (
        <div className="flex h-dvh bg-gray-100 font-['Inter',sans-serif]">
            {joyrideActive && <Joyride
                run={joyrideRunning}
                continuous={false}
                showProgress={true}
                callback={handleJoyrideCallback}
                steps={steps}
                stepIndex={stepIndex}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
            />}
            <SidebarMobile toggled={toggled} toggleMenuEvent={toggleMenuEvent} />
            <Sidebar />
            <div className="flex-1 flex flex-col sm:!my-[24px] mx-0 px-2 sm:px-auto sm:mx-auto w-full sm:w-auto">
                <Header backEnabled={backEnabled} setBackEvent={setBackEvent} toggleMenuEvent={toggleMenuEvent} />
                <div className="flex-grow overflow-scroll sm:overflow-hidden sm:mt-[27px] sm:mr-[29px] pt-[27px] px-[10px] sm:!px-[24px] sm:border rounded-[8px] border-[#E4E7EC]">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Main;
