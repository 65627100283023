import React, { useState, useRef, useEffect } from 'react';

import NewView from '../common/NewView';

const WelcomeFinished = ({onClick}) => {
  return (
    <NewView>
      <div className="fixed z-[9999999] top-0 left-0 w-dvw h-dvh flex flex-col items-center justify-center bg-dark-gray-transparent">
        <div className="bg-white p-4 rounded-lg text-center w-[70%] h-auto flex flex-col items-center justify-between space-y-2 border rounded-[8px] border-[#E4E7EC]">
          <h2 className="text-xl font-bold mb-2">Congratulations!</h2>
          <p className="text-sm text-gray-600 mb-2">You have successfully set up a basic netizen!</p>
          <p className="text-sm text-gray-600 mb-2">We have automatically configured a simple listicle pipeline for you, so you should start seeing videos post to your account beginning tomorrow morning. Look out for an email from us!</p>
          <p className="text-sm text-gray-600 mb-2">Happy creating!</p>
          <button
            onClick={onClick}
            className="w-1/2 bg-black py-2 px-4 rounded transition-colors text-text-secondary-light bg-gradient-to-br from-gradient-blue to-gradient-purple font-light"
          >
            Continue
          </button>
        </div>
      </div>
    </NewView>
  )
}

export default WelcomeFinished;