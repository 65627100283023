const ModelCardMini = ({ model, onClick }) => (
    <div 
      className={`mt-1 h-auto w-[200px] relative rounded-[13px] overflow-hidden border border-light-purple \
        ${onClick ? "cursor-pointer hover:shadow-md transition-shadow" : ""}`} 
      onClick={onClick}>
      <img src={model?.picture} alt={model?.name} className="w-[200px] h-auto object-cover" />
      <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black to-transparent"></div>
      <div className="flex px-[13px] py-[10px] absolute bottom-0 left-0 w-full h-2/5 flex-col">
        <h3 className="text-white text-sm font-semibold mb-2">{model?.name}</h3>
        <p className="h-full text-white text-xs mb-0">{model?.description}</p>
      </div>
    </div>
  );

export default ModelCardMini;