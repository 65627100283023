import React, { useState, useRef, useEffect } from 'react';

import config from '../../config/config';

import CardView from '../common/CardView';
import NewNetizenView from "./NewNetizenView";
import PreviewNetizenView from "./PreviewNetizenView";
import AlertView from "../common/AlertView";
import EditNetizenView from "./EditNetizenView";
import NetizenCard from './NetizenCard';
import Welcome from '../Welcome/Welcome';

// tour
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS, CallBackProps, LIFECYCLE } from 'react-joyride';

import axios from 'axios';
import globalConfig from '../../config/config';
import { updateUser, getAuth, authHeader } from '../../utils/auth';

const Netizens = ({backEvent, setBackEvent, setBackEnabled}) => {
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`
  const API_ENDPOINT_NETIZENS = `${globalConfig.api}/netizens`
  const API_ENDPOINT_VOICES = `${globalConfig.api}/voices`
  const API_ENDPOINT_MODELS = `${globalConfig.api}/models`

  // get some info to know what joyride steps to show users
  const [joyrideRunning, setJoyrideRunning] = useState(true);
  const [joyrideActive, setJoyrideActive] = useState(false);
  const [steps, setSteps] = useState(config.joyride.netizens);
  const [stepIndex, setStepIndex] = useState(0);

  const [view, setView] = useState('main');
  const [voices, setVoices] = useState(null);
  const [models, setModels] = useState(null);
  const [netizens, setNetizens] = useState(null);
  const [activeNetizen, setActiveNetizen] = useState(null);
  const [netizenCards, setNetizenCards] = useState(null);
  const [publicNetizenCards, setPublicNetizenCards] = useState(null);
  const [message, setMessage] = useState('');
  const [messageFirst, setMessageFirst] = useState('You have successfully created your first netizen! Next, go to the accounts page to link your social account.');

  const [limit, setLimit] = useState(true);
  const [tag, setTag] = useState(null);
  const [footer, setFooter] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [busy, setBusy] = useState(false);

  const [mustCreateNetizen, setMustCreateNetizen] = useState(false);

  // joyride is only active when there are no netizens and the user hasn't completed the tour
  useEffect(() => {
    const _runOnNewNetizens = async () => {
      //const user = await updateUser();
      //const tour = user?.tour;

      // if we've loaded but there are no netizens, the user is early on in their journey
      if(netizens !== null && netizens.length === 0) {
        // check browser cookies if they've already seen the welcome screen in the past hour
        const now = new Date();
        const lastWelcome = localStorage.getItem('lastWelcome') || new Date(0);
        const diff = Math.abs(now - new Date(lastWelcome));
        const diffHours = Math.floor(diff / 3600000);

        if(diffHours > 1) {
          // temporarily remove the welcome screen...
          //setView('welcome');
          //setJoyrideActive(false);
          // set view to new
          setView('new');
          setStepIndex(1);
          setJoyrideActive(true);
        } else {
          // if we're not going to show the welcome screen, we want to go straight into netizen creation
          setView('new');
          setStepIndex(1);
          setJoyrideActive(true);
        }

        setMustCreateNetizen(true);
      }

      if(netizens && netizens.length > 0) {
        setJoyrideActive(false);
        setMustCreateNetizen(false);
      }
    }

    _runOnNewNetizens();
  }, [netizens]);

  // callback
  const handleJoyrideCallback = (data) => {
    const { action, index, type, status, lifecycle } = data;

    if(lifecycle === LIFECYCLE.COMPLETE) {
      // for previous, go to previous step
      if (stepIndex > 1 && action === ACTIONS.PREV) {
        setStepIndex(Math.max(0, stepIndex - 1));
      }

      // for next, go to next step
      if ([1, 2, 3, 4].includes(stepIndex) && [ACTIONS.NEXT, ACTIONS.CLOSE, ACTIONS.SKIP].includes(action)) {
        setStepIndex(stepIndex + 1);
      }
    }
  }

  // set limit based on account type and netizens
  useEffect(() => {
    const limits = globalConfig.limits;
    const auth = getAuth();
    const role = auth?.user?.role;

    const roleLimits = limits[role];
    if(roleLimits) {
      const curLimit = roleLimits.netizens;

      if(curLimit < 0 || netizens?.length < curLimit) {
        setLimit(false);
        setFooter([]);
      } else {
        setLimit(true);
        setFooter([
          <span>You've reached your netizen limit ({netizens?.length || 0}/{curLimit<0?'∞':curLimit}) you are only allowed to have {curLimit<0?'∞':curLimit} netizens as a {roleLimits.label} user</span>,
          <span>When you upgrade your plan, you can create more netizens.</span>
        ]);
      }

      setTag(`${roleLimits.label} ${netizens?.length || 0}/${curLimit<0?'∞':curLimit}`);
    } else {
      setLimit(true);
      setFooter([]);
    }
  }, [netizens]);

  useEffect(() => {
    loadVoices();
    loadModels();
  }, []);

  useEffect(() => {
    if(voices && models) {
      loadNetizens();
    }
  }, [voices, models]);

  useEffect(() => {
    switch(view) {
      case 'welcome':
        setBackEnabled(false);
        break;
      case 'new':
        setBackEnabled(!mustCreateNetizen);
        break;
      case 'preview':
        setBackEnabled(false);
        break;
      case 'success':
        setBackEnabled(false);
        break;
      case 'success-first':
        setBackEnabled(false);
        break;
      case 'error':
        setBackEnabled(false);
        break;
      case 'edit':
        setBackEnabled(!mustCreateNetizen);
        break;
      default:
        setBackEnabled(false);
        break;
    }
  }, [view, setBackEnabled, mustCreateNetizen]);

  useEffect(() => {
    if(backEvent) {
      setView('main');
      setBackEvent(false);
    }
  }, [backEvent, setBackEvent]);

  // load available netizens for the current user
  const loadNetizens = () => {
    const {user} = getAuth();
    const user_id = user.id;
    
    return axios.get(API_ENDPOINT_NETIZENS, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        const resNetizens = response.data.results.map(m => { 
          return {
            id: m.id,
            name: m.name,
            description: m.description,
            persona: m.persona,
            model: m.model,
            voice: m.voice,
            owner: m.owner === user_id
          }
        })

        // try mapping the models and voices to the netizens
        resNetizens.forEach(n => {
          if(n.model) {
            const model = models.find(m => m.id === n.model);
            n.model = model;
          }

          if(n.voice) {
            const voice = voices.find(v => v.id === n.voice);
            n.voice = voice;
          }
        });
        
        // set netizens
        setNetizens(resNetizens);

        // set netizen cards for public/private
        setNetizenCards(resNetizens.filter(m => m.owner).map(m => <NetizenCard key={m.id} netizen={m} onClick={() => onCardClick(m)} />));
        setPublicNetizenCards(resNetizens.filter(m => !m.owner).map(m => <NetizenCard key={m.id} netizen={m} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  // load available voices for the current user
  const loadVoices = () => {
    const {user} = getAuth();
    const user_id = user.id;
    
    return axios.get(API_ENDPOINT_VOICES, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        const resVoices = response.data.results.map(m => { 
          return {
            id: m.id,
            name: m.name,
            description: m.description,
            active: m.active,
            file: m.file ? `${S3_PATH_PREFIX}/${m.file}` : null,
            sample: m.sample ? `${S3_PATH_PREFIX}/${m.sample}` : null,
            owner: m.owner === user_id
          }
        })
        
        setVoices(resVoices);
      })
      .catch(error => {
        console.error(error);
      });
  };

  // load available models for the current user
  const loadModels = () => {
    const {user} = getAuth();
    const user_id = user.id;
    
    return axios.get(API_ENDPOINT_MODELS, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        const resModels = response.data.results.map(m => { 
          return {
            id: m.id,
            name: m.name,
            description: m.description,
            picture: m.picture,
            public: m.public,
            file: m.file,
            owner: m.owner === user_id
          }
        })
        
        setModels(resModels);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onSearch = (e) => {
    //alert(`Searching...${e.target.value}`);
  };

  const onCardClick = (netizen) => {
    setActiveNetizen(netizen);
    setView('edit');
  }

  const onClickNew = (e) => {
    if(!limit) {
      setView('new');
      setStepIndex(1);
    } else {
      alert('Upgrade your plan.');
    }
  };

  const onClickUploadSkipPreview = (netizen) => {
    console.log('Preview + upload netizen...', netizen);

    try {
      // run preview step
      let newNetizen = {
        ...netizen,
        model: netizen?.model ? models.find(m => m.id === netizen.model) : null,
        voice: voices.find(v => v.id === netizen.voice)
      }

      // run upload step
      newNetizen = {
          ...newNetizen,
          model: newNetizen?.model?.id,
          voice: newNetizen?.voice?.id,
      }

      console.log('Creating netizen...', newNetizen);

      handleUpload(newNetizen).then(() => {
        if(mustCreateNetizen) {
          setView('success-first');
        } else {
          setMessage('You have successfully uploaded a netizen');
          setView('success');
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(error?.response?.data?.message || error.message || error || 'An error occurred');
        setView('error');
      });
    } catch(e) {
      console.error(e);
      setMessage(e || 'An error occurred');
      setView('error');
    }
  }

  const onClickPreview = (netizen) => {
    console.log('Previewing netizen...', netizen);

    try {
      const newNetizen = {
        ...netizen,
        model: netizen?.model ? models.find(m => m.id === netizen.model) : null,
        voice: voices.find(v => v.id === netizen.voice)
      }

      setActiveNetizen(newNetizen);
      setView('preview');
    } catch(e) {
      console.error(e);
      setMessage(e || 'An error occurred');
      setView('error');
    }
  }

  const onClickUpload = (netizen) => {
    console.log(`Uploading netizen...${netizen}`);

    handleUpload(netizen).then(() => {
      setMessage('You have successfully uploaded a netizen');
      setView('success');
    })
    .catch(error => {
      console.error(error);
      setMessage(error?.response?.data?.message || error.message || error || 'An error occurred');
      setView('error');
    });
  }

  const onClickEdit = (netizen) => {
    console.log(`Saving netizen...`);
    console.log(netizen);

    handleUpdate(netizen).then(() => {
      setMessage('You have successfully updated the netizen');
      setView('success');
    })
    .catch(error => {
      console.error(error);
      setMessage(error?.response?.data?.message || error.message || error || 'An error occurred');
      setView('error');
    });
  }

  const onClickDelete = (netizen) => {
    console.log(`Deleting netizen...${netizen}`);

    handleDelete(netizen).then(() => {
      setMessage('You have successfully deleted the netizen');
      setView('success');
    })
    .catch(error => {
      console.error(error);
      setMessage(error?.response?.data?.message || error.message || error || 'An error occurred');
      setView('error');
    });
  }

  // create a new netizen
  const handleUpload = (netizen) => {
    // Reset progress
    setUploadProgress(0);

    // Create form data
    const formData = new FormData();
    formData.append('name', netizen.name);
    if(netizen.description) formData.append('description', netizen.description);
    if(netizen.persona) formData.append('persona', netizen.persona);
    if(netizen.model) formData.append('model', netizen.model);
    formData.append('voice', netizen.voice);

    // set busy state
    setBusy(true);

    // post to endpoint
    return axios.post(API_ENDPOINT_NETIZENS, formData, {
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 80) / progressEvent.total);
        setUploadProgress(percentCompleted);
      },
    })
    .then(response => {
      setUploadProgress(100);

      // reload netizens
      loadNetizens();

      // reset busy state
      setBusy(false);

      const {user} = getAuth();
      let userTour = user.tour || {};

      // set user tour status if this was the first netizen
      if(!userTour.netizens) {
        const user_id = user.id;
        axios.patch(`${globalConfig.api}/users/${user_id}`, {
          tour: {
            ...userTour,
            netizens: true
          }
        }, {
          headers: {
            ...authHeader()
          }
        })
        .then(response => {
          console.log('user tour updated');
        })
        .then(updateUser)
        .catch(error => {
          console.error(error);
        });
      }
    })
    .catch(error => {
      console.error(error);

      // reset busy state
      setBusy(false);
      throw error;
    });
  };

  // update a netizen
  const handleUpdate = (netizen) => {
    return axios.patch(
      `${API_ENDPOINT_NETIZENS}/${netizen.id}`, 
      {
        name: netizen.name,
        description: netizen.description,
        model: netizen.model,
        voice: netizen.voice,
        public: netizen.public
      },
      {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        // reload netizens
        loadNetizens();
      })
  }

  // delete a netizen
  const handleDelete = (netizen) => {
    return axios.delete(`${API_ENDPOINT_NETIZENS}/${netizen.id}`, {
      headers: {
        ...authHeader()
      }
    })
    .then(response => {
      // reload netizens
      loadNetizens();
    })
  }

  // click out of welcome
  const onClickWelcome = () => {
    // set last welcome to now
    const now = new Date();
    localStorage.setItem('lastWelcome', now);

    // set view to new
    setView('new');
    setStepIndex(1);
    setJoyrideActive(true);
  }

  return [
    (joyrideActive && <Joyride
      run={joyrideRunning}
      continuous={false}
      showProgress={true}
      callback={handleJoyrideCallback}
      steps={steps}
      stepIndex={stepIndex}
    />),(
    (view==='welcome' && <Welcome
      onClick={onClickWelcome}
    />) ||
    (view==='main' && <CardView
      view={view}
      cards={netizenCards} 
      publicCards={publicNetizenCards} 
      toggle={false} 
      title="Netizens"
      description="Find all the netizens you have created here"
      info={tag}
      label="Create Netizen"
      onClick={onClickNew}
      onSearch={onSearch}
      footer={footer}
      emptyTitle='No netizens created yet'
      emptyDescription='Click "Create new netizen" button to get started generating content automatically.'
      emptyLabel='Create new netizen'
      id='netizens'
    />) ||
    (view==='new' && <NewNetizenView
      models={models}
      voices={voices}
      // onClickPreview={onClickPreview}
      onClickPreview={onClickUploadSkipPreview}
    />) ||
    (view==='preview' && <PreviewNetizenView 
      name={'Storia'}
      description={'A netizen created using vroidstudio'}
      netizen={activeNetizen}
      uploadProgress={uploadProgress}
      onUpload={onClickUpload}
      onBack={()=>setView('new')}
    />) ||
    (view==='success' && <AlertView
      title={"Successful"}
      description={message}
      label={"Continue"}
      onClick={()=>setView('main')}
    />) || 
    (view==='success-first' && <AlertView
      title={"Successful"}
      description={messageFirst}
      label={"Continue"}
      onClick={()=>{window.location.href = '/accounts'}}
    />) || 
    (view==='error' && <AlertView
      title={"Error"}
      description={message}
      label={"Continue"}
      onClick={()=>setView('main')}
    />) ||
    (view==='edit' && <EditNetizenView 
      netizen={activeNetizen}
      models={models}
      voices={voices}
      onClickSave={onClickEdit}
      onClickDelete={onClickDelete}
    />)
  )];
}

export default Netizens;