import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

import axios from 'axios';
import config from '../../config/config';
import ConnectedaccountService from '../../services/connectedaccount.service';
import OAuth2Login from 'react-simple-oauth2-login';

import sha256 from 'crypto-js/sha256';
import EncHex from 'crypto-js/enc-hex';

const NewAccountViewMain = ({models, voices, onClickAuthorize}) => {
  const ACCOUNT_OPTIONS = [
    // {value: 'twitter', label: 'Twitter'}, 
    // {value: 'facebook', label: 'Facebook'}, 
    // {value: 'instagram', label: 'Instagram'},
    // {value: 'linkedin', label: 'LinkedIn'},
    // {value: 'reddit', label: 'Reddit'},
    {value: 'tiktok', label: 'TikTok'},
    {value: 'youtube', label: 'YouTube'},
  ];

  const [isValid, setIsValid] = useState(false);

  const [activeAccount, setActiveAccount] = useState({
    name: null,
    description: '',
    type: null,
    publish: false,
  });

  useEffect(() => {
    if (activeAccount.name && activeAccount.type) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [activeAccount]);

  function generateRandomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const oauth2_instagram = async () => {
    // save state in local storage
    localStorage.setItem('instagram_account_name', activeAccount?.name);
    localStorage.setItem('instagram_account_description', activeAccount?.description);

    const url = 
    config.instagram.authorization_url + 
    '/?client_id=' + 
    encodeURIComponent(config.instagram.client_id) + 
    '&scope=' +
    encodeURIComponent(config.instagram.scopes.join(',')) +
    '&response_type=code' +
    '&redirect_uri=' +
    encodeURIComponent('https://netizens.ai/oauth2/instagram');

    const win = window.open(url, 'instagram', 'width=500,height=600');
    win.focus();

    // wait for popup to close
    const interval = setInterval(() => {
      if (win.closed) {
        clearInterval(interval);
        
        // refresh page because we probably have a new connected account
        window.location.reload();
      }
    }, 1000);
  }

  const oauth2_tiktok = async () => {
    // code challenge must be SHA256 hex string
    const csrfCodeChallenge = decodeURIComponent(generateRandomString(60));
    const csrfState = EncHex.stringify(sha256(csrfCodeChallenge));

    // save state in local storage
    localStorage.setItem('tiktok_csrf_code_challenge', csrfCodeChallenge);
    localStorage.setItem('tiktok_csrf_state', csrfState);
    localStorage.setItem('tiktok_account_name', activeAccount?.name);
    localStorage.setItem('tiktok_account_description', activeAccount?.description);

    const url = 
      config.tiktok.authorization_url + 
      '/?client_key=' + 
      encodeURIComponent(config.tiktok.client_id) + 
      '&scope=' +
      encodeURIComponent(config.tiktok.scopes.join(',')) +
      '&response_type=code' +
      '&redirect_uri=' +
      encodeURIComponent('https://netizens.ai/oauth2/tiktok') +
      '&code_challenge=' +
      csrfCodeChallenge +
      '&code_challenge_method=S256' +
      '&state=' +
      csrfState;

    const win = window.open(url, 'tiktok', 'width=500,height=600');
    win.focus();

    // wait for popup to close
    const interval = setInterval(() => {
      if (win.closed) {
        clearInterval(interval);

        // go back
        onClickAuthorize();
        
        // // refresh page because we probably have a new connected account
        // window.location.reload();
      }
    }, 1000);
  }

  const oauth2_onSuccessGoogle = (response) => {
    const code = response.code
  
    if (code) {
      ConnectedaccountService.createConnectedaccountByYoutube(
        activeAccount.name,
        activeAccount.description,
        activeAccount.type,
        code,
        (config.url+'/accounts').replace(/([^:]\/)\/+/g, "$1"),
        ).then(
        (data) => {
          // should be new connected account
          console.log(data);

          // go back
          onClickAuthorize();
        },
        (error) => {
          console.log(error)
        }
      );
    }
  };

  const oauth2_onFailure = response => console.error(response);

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              New Account
            </h1>
          </div>
        </div>
      </div>
      
      <div className="h-full overflow-scroll">
        <Fields.TextField label="Name" placeholder="Account Name" value={activeAccount.name} onChange={(e) => {setActiveAccount({...activeAccount, name: e.target.value})}} id="accountname"/>
        {/* <Fields.DescriptionField label="Description" placeholder="Description" value={activeAccount.description} onChange={(e) => {setActiveAccount({...activeAccount, description: e.target.value})}} /> */}
        <Fields.EnumField2 
          label="Auto-Publish" 
          info="Should videos be auto-published?" 
          value={activeAccount?.publish} 
          setValue={(value) => setActiveAccount({...activeAccount, publish: value})}
          values={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]} 
        />
        <Fields.SingleSelect label="Select Type" info="(you can only select one)" value={activeAccount.type} onChange={(value) => {setActiveAccount({...activeAccount, type: value})}} options={ACCOUNT_OPTIONS} id="account"/>
        <div className="flex flex-row space-x-4 mb-4" id="button-create">
        {
          activeAccount.type === 'youtube' && 
            <OAuth2Login
              authorizationUrl={config.youtube.authorization_url}
              responseType="code"
              clientId={config.youtube.client_id}
              redirectUri={(config.url+'/accounts').replace(/([^:]\/)\/+/g, "$1")}
              scope={config.youtube.scopes.join(' ')}
              extraParams={{ access_type: 'offline', prompt: 'consent' }}
              onSuccess={oauth2_onSuccessGoogle}
              onFailure={oauth2_onFailure}
              render={({ onClick }) => {
                return <Fields.Button type='wide-styled' label='Authorize Youtube' onClick={onClick}  disabledLabel="Fill in all fields..." disabled={!isValid}/>
                }}
            />
        }
        {
          activeAccount.type === 'tiktok' && <Fields.Button type='wide-styled' label='Authorize TikTok' onClick={()=>{oauth2_tiktok()}} disabledLabel="Fill in all fields..." disabled={!isValid}/>
        }
        {
          activeAccount.type === 'instagram' && <Fields.Button type='wide-styled' label='Authorize Instagram' onClick={()=>{oauth2_instagram()}}  disabledLabel="Fill in all fields..." disabled={!isValid}/>
        }
        </div>
      </div>
    </NewView>
  );
};

const NewAccountView = ({onClickAuthorize}) => {
  return (
    <NewAccountViewMain onClickAuthorize={onClickAuthorize} />
  );
}

export default NewAccountView;