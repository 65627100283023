import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

serviceWorker.unregister();