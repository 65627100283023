// pricing config
const stripe_pricing = [
    {
        stripe: {
            priceId: '',
            mode: 'payment'
        },
        type: 'one-time',
        visual: {
            title: 'Micro',
            price: '$10',
            features: [
                '100 credits',
            ],
        }
    },
    {
        stripe: {
            priceId: '',
            mode: 'payment'
        },
        type: 'one-time',
        visual: {
            title: 'Standard',
            price: '$100',
            features: [
                '1000 credits',
            ],
        }
    },
    {
        stripe: {
            priceId: '',
            mode: 'payment'
        },
        type: 'one-time',
        visual: {
            title: 'Scale',
            price: '$1000',
            features: [
                '10000 credits',
            ],
        }
    },
    {
        stripe: {
            priceId: '',
            mode: 'subscription'
        },
        type: 'monthly',
        visual: {
            title: 'Free Plan',
            price: '$0',
            features: [
                '100 credits per month',
                '1 netizen limit',
                'Netizens retain up to 3 months of memories',
                'Up to 4 content creations per month',
                'Can only access public voices',
                'Limited social media accounts',
                'Access to standard content styles',
            ],
        }
    },
    {
        stripe: {
            priceId: 'price_1PlhIsLC2jyPSEotmqvilszN',
            mode: 'subscription'
        },
        type: 'monthly',
        visual: {
            title: 'Pro Plan',
            price: '$49',
            features: [
                '600 credits per month',
                '5 netizen limit',
                'Netizens retain up to 6 months of memories',
                'Up to 50 content creations per month',
                'Custom voice creation',
                'Unlimited social media accounts',
                'Access to pro content styles',
            ],
        }
    },
    {
        stripe: {
            priceId: 'price_1PlhKTLC2jyPSEotsCzjL7kx',
            mode: 'subscription'
        },
        type: 'monthly',
        visual: {
            title: 'Scale Plan',
            price: '$199',
            features: [
                '3000 credits per month',
                '15 netizen limit',
                'Netizens retain up to 3 years of memories',
                'Up to 300 content creations per month',
                'Custom voice creation',
                'Unlimited social media accounts',
                'Full custom content styles',
            ],
        }
    },
]

const limits = {
    new: {
        label: 'New',
        netizens: 0,
        memories: 0,
        models: 0,
        voices: 0,
        accounts: 0,
        pipelines: 0,
        schedules: 0,
    },
    user: {
        label: 'Free',
        netizens: 1,
        memories: -1,
        models: -1,
        voices: 0,
        accounts: 1,
        pipelines: 1,
        schedules: 1,
    },
    pro: {
        label: 'Pro',
        netizens: 5,
        memories: -1,
        models: -1,
        voices: 5,
        accounts: 5,
        pipelines: 10,
        schedules: 10,
    },
    scale: {
        label: 'Scale',
        netizens: 15,
        memories: -1,
        models: -1,
        voices: 15,
        accounts: 15,
        pipelines: 30,
        schedules: 30,
    },
    admin: {
        label: 'Admin',
        netizens: -1,
        memories: -1,
        models: -1,
        voices: -1,
        accounts: -1,
        pipelines: -1,
        schedules: -1,
    },
}

const joyride_steps = [
    {
        target: '#sidebar #sidebar-element-Netizens',
        content: 'This tab is used to create and manage netizens. Let\'s create your first netizen!',
    },
    {
        target: '#sidebar #sidebar-element-Social-accounts',
        content: 'This tab is used to link and manage social accounts. Let\'s link your first account!',
    },
    {
        target: '#sidebar #sidebar-element-Pipelines',
        content: 'This tab is used to create and manage content pipelines. Let\'s create your first pipeline!',
    },
    {
        target: '#sidebar #sidebar-element-Schedules',
        content: 'This tab is used to create and manage schedules. Let\'s create your first schedule!',
    },
]

const joyride_steps_mobile = [
    {
        target: '#menu',
        content: 'Click here to access the mobile menu!',
    },
    {
        target: '#sidebar-mobile #sidebar-element-Netizens',
        content: 'This tab is used to create and manage netizens. Let\'s create your first netizen!',
    },
    {
        target: '#menu',
        content: 'Click here to link an account.',
    },
    {
        target: '#sidebar-mobile #sidebar-element-Social-accounts',
        content: 'This tab is used to link and manage social accounts. Let\'s link your first account!',
    },
    {
        target: '#menu',
        content: 'Click here to configure a pipeline.',
    },
    {
        target: '#sidebar-mobile #sidebar-element-Pipelines',
        content: 'This tab is used to create and manage content pipelines. Let\'s create your first pipeline!',
    },
    {
        target: '#menu',
        content: 'Click here to set up a schedule.',
    },
    {
        target: '#sidebar-mobile #sidebar-element-Schedules',
        content: 'This tab is used to create and manage schedules. Let\'s create your first schedule!',
    },
]

const joyride_steps_netizen = [
    {
        target: '#empty-view-create-new',
        content: 'Click here to create your first netizen!',
    },
    {
        target: '#field-text-name',
        content: 'Fill in information about your netizen.',
    },
    {
        target: '#field-description-persona',
        content: 'Your netizen\'s persona is the initial personality you want to give it. Be creative!',
    },
    {
        target: '#field-single-select-voice',
        content: 'These are the default voices you can pick from. You can also create your own voice later! Check the Voices tab to listen to them.',
    },
    {
        target: '#field-single-select-model',
        content: 'These are public models you can pick from. But a netizen doesn\'t need a model to function. You can also create your own model later! Check the Models tab to see them.',
    },
    {
        target: '#button-create',
        content: 'Once you\'ve filled out all of the required info you\'ll be able to create your netizen!',
    }
]

const joyride_steps_pipeline = [
    {
        target: '#empty-view-create-new',
        content: 'Click here to create your first pipeline!',
    },
    {
        target: '#list-view-title',
        content: 'These are pipelines templates you can choose from. You can configure them to create content for your netizens.',
    },
    {
        target: '#list-view-label1',
        content: 'Pick a template to create a new pipeline.',
    },
    {
        target: '#field-text-pipelinename',
        content: 'Name your pipeline to keep track of it.',
    },
    {
        target: '#text-let-the-netizen-decide-for-you',
        content: 'Some fields let you use the "Let the netizen decide for you" option. This will allow the netizen to make a decision at runtime based on its persona and memories.',
    },
    {
        target: '#button-create',
        content: 'Once you\'ve filled out all of the required info you\'ll be able to create your pipeline!',
    }
]

const joyride_steps_accounts = [
    {
        target: '#empty-view-create-new',
        content: 'Click here to link your first account!',
    },
    {
        target: '#field-text-accountname',
        content: 'Name your account to keep track of it.',
    },
    {
        target: '#field-single-select-account',
        content: 'These are the account types we support. Check back often as we add more every day!',
    },
    {
        target: '#button-create',
        content: 'Once you\'ve filled out all of the required info you\'ll be able to link your account!',
    }
]

const joyride_steps_schedule = [
    {
        target: '#empty-view-create-new',
        content: 'Click here to create your first schedule!',
    },
    {
        target: '#field-text-name',
        content: 'Fill in information about your schedule.',
    },
    {
        target: '#field-multi-enum-netizens',
        content: 'You can pick which of your netizens should be involved in this scheduled content.',
    },
    {
        target: '#field-single-select-pipeline',
        content: 'Pick which pipeline should be run whenever this schedule is triggered.',
    },
    {
        target: '#field-multi-enum-accounts',
        content: 'You can post to multiple accounts at the same time whenever content is generated. So easy!',
    },
    {
        target: '#field-date-start',
        content: 'You can set the start and end dates for your schedule. The schedule will automatically be set to inactive after the end date.',
    },
    {
        target: '#field-multi-enum-days',
        content: 'Set which days of the week you want the schedule to run.',
    },
    {
        target: '#field-enum-enabled',
        content: 'If you\'re not quite ready to have this run yet, you can keep it disabled until you are.',
    },
    {
        target: '#button-create',
        content: 'Once you\'ve filled out all of the required info you\'ll be able to create your schedule!',
    }
]

const default_user_content_config = {
    pipeline: {
        public: false,
        name: 'Your First Pipeline - Listicle',
        description: 'This is your first pipeline. It will generate content for your netizens.',
        type: "ListiclePipeline",
        config: {
            credits: 50,
            topic: null,
            items: 3,
            generate_selfie: true,
        }
    },
    schedule: {
        name: 'Your First Schedule - Once a Day',
        description: 'This is your first schedule. It will run your pipeline and post to your accounts.',
        netizens: [
            // put user netizen here
        ],
        accounts: [
            // put user account here
        ],
        pipeline: null, // put user pipeline here
        public: false,
        active: true,
        daysOfTheWeek: [0, 1, 2, 3, 4, 5, 6],
        timesPerDay: 1,
        startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    },
}

var _config = {}

// environment
if(process.env.NODE_ENV === 'development') {
    _config = {
        api: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:3000/v1/',
        url: process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : 'http://localhost:3001/',
        limits: limits,
        google: {
            authorization_url: 'https://accounts.google.com/o/oauth2/auth',
            client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
            scopes: [
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile',
                'openid'
            ]
        },
        youtube: {
            authorization_url: 'https://accounts.google.com/o/oauth2/auth',
            client_id: process.env.REACT_APP_YOUTUBE_OAUTH_CLIENT_ID,
            scopes: [
                'https://www.googleapis.com/auth/youtube.readonly',
                'https://www.googleapis.com/auth/youtube.upload'
            ], 
        },
        stripe: {
            pricing: stripe_pricing
        },
        tiktok: {
            authorization_url: 'https://www.tiktok.com/v2/auth/authorize',
            client_id: process.env.REACT_APP_TIKTOK_OAUTH_CLIENT_ID,
            scopes: [
                'user.info.basic',
                'video.publish',
                'video.upload',
            ]
        },
        instagram: {
            authorization_url: 'https://api.instagram.com/oauth/authorize',
            client_id: process.env.REACT_APP_INSTAGRAM_OAUTH_CLIENT_ID,
            scopes: [
                'business_basic',
                'business_content_publish',
            ]
        },
        joyride: { 
            steps: joyride_steps,
            steps_mobile: joyride_steps_mobile,
            netizens: joyride_steps_netizen,
            pipelines: joyride_steps_pipeline,
            accounts: joyride_steps_accounts,
            schedules: joyride_steps_schedule,
        },
        default_user_content_config: default_user_content_config,
    }
} else if(process.env.NODE_ENV === 'production') {
    _config = { 
        api: process.env.REACT_APP_API_URL,
        url: process.env.REACT_APP_PUBLIC_URL,
        limits: limits,
        google: {
            authorization_url: 'https://accounts.google.com/o/oauth2/auth',
            client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
            scopes: [
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile',
                'openid'
            ]
        },
        youtube: {
            authorization_url: 'https://accounts.google.com/o/oauth2/auth',
            client_id: process.env.REACT_APP_YOUTUBE_OAUTH_CLIENT_ID,
            scopes: [
                'https://www.googleapis.com/auth/youtube.readonly',
                'https://www.googleapis.com/auth/youtube.upload'
            ], 
        },
        stripe: {
            pricing: stripe_pricing
        },
        tiktok: {
            authorization_url: 'https://www.tiktok.com/v2/auth/authorize',
            client_id: process.env.REACT_APP_TIKTOK_OAUTH_CLIENT_ID,
            scopes: [
                'user.info.basic',
                'video.publish',
                'video.upload',
            ]
        },
        instagram: {
            authorization_url: 'https://api.instagram.com/oauth/authorize',
            client_id: process.env.REACT_APP_INSTAGRAM_OAUTH_CLIENT_ID,
            scopes: [
                'business_basic',
                'business_content_publish',
            ]
        },
        joyride: { 
            steps: joyride_steps,
            steps_mobile: joyride_steps_mobile,
            netizens: joyride_steps_netizen,
            pipelines: joyride_steps_pipeline,
            accounts: joyride_steps_accounts,
            schedules: joyride_steps_schedule,
        },
        default_user_content_config: default_user_content_config,
    }
} else {
    _config = { }
}

const config = _config

export default config


