import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { ArrowDownToLine, Users, Mic, Brain, Target, Pipette, Calendar, FileText, Settings, HelpCircle, LogOut, Menu } from 'lucide-react';

import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';
import AuthService from '../../services/auth.service';

const SidebarItem = ({ icon: Icon, label, to, disabled=false, onClick=()=>{} }) => {
  const location = useLocation();
  const isSelected = location.pathname === to;

  return (
    <li id={`sidebar-element-${label.replace(' ','-')}`} className="w-full">
      <Link 
        to={disabled ? '#' : to}
        onClick={() => {
          onClick()
        }}
        className={`flex items-center p-2 rounded-md cursor-pointer transition-colors duration-200 w-full text-xs font-normal
          ${isSelected 
            ? 'text-text-secondary-light bg-gradient-to-br from-gradient-blue to-gradient-purple font-light hover:text-[rgba(30,30,30,1)]'
            : 'text-text-secondary hover:bg-[#f9fafb] hover:text-[rgba(30,30,30,1)]'}
          ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
          no-underline`}
      >
        <Icon size={18} className="mr-2" color={isSelected ? "white" : "black"} /> {label}
      </Link>
    </li>
  );
};

const SidebarMobile = ({toggled, toggleMenuEvent}) => {
  const [user, setUser] = useState(null);
  const [menuVisibility, setMenuVisibility] = useState('netizens-only');
  const menuItems = [
    { label: 'Netizens', icon: ArrowDownToLine, to: '/netizens', disabled: menuVisibility === 'accounts-only' },
    { label: 'Memories', icon: Brain, to: '/memories', disabled: menuVisibility !== 'all' },
    { label: 'Models', icon: Users, to: '/models', disabled: menuVisibility !== 'all' },
    { label: 'Voices', icon: Mic, to: '/voices', disabled: menuVisibility !== 'all' },
    { label: 'Social accounts', icon: Target, to: '/accounts', disabled: menuVisibility === 'netizens-only' },
    { label: 'Pipelines', icon: Pipette, to: '/pipelines', disabled: menuVisibility !== 'all' },
    { label: 'Schedules', icon: Calendar, to: '/schedules', disabled: menuVisibility !== 'all' },
    { label: 'Content runs', icon: FileText, to: '/runs', disabled: menuVisibility !== 'all' },
  ];

  // get user on mount
  useEffect(() => {
    const authInfo = getAuth();

    if(authInfo?.user) {
      setUser(authInfo.user);
    }
  }, []);

  // on user change
  useEffect(() => {
    if (user) {
      const tour = user.tour || {};

      // if they haven't made a netizen, show the netizen only menu
      if (!tour.netizens) {
        setMenuVisibility('netizens-only');
      }

      // if they've made a netizen, but no accounts, show the accounts only menu
      else if (tour.netizens && !tour.accounts) {
        setMenuVisibility('accounts-only');
      }

      // in all other caes, show all menus
      else {
        setMenuVisibility('all');
      }
    }
  }, [user]);

  // logout
  const onLogout = () => {
    const {tokens} = getAuth();
    AuthService.logout(tokens.refresh.token)
      .then((data) => {
        localStorage.removeItem('tokens');
        localStorage.removeItem('user');

        window.location.href = '/';
      })
  }

  return (
     <div className={`absolute z-[1000] w-screen p-0 ${toggled ? "flex" : "hidden"} sm:hidden flex-col`} id="sidebar-mobile">
        <div className="flex flex-col h-full w-screen py-[25px] px-[17px] bg-light-purple">
          <div className="flex flex-row h-full w-full justify-between">
            <button className="flex flex-row mt-[-13px] ml-[-9px] hover:bg-gray-100 p-2 rounded-md transition-colors duration-200"
              onClick={toggleMenuEvent}
            >
              <Menu size={27} className="ml-2" />
            </button>
            <a href={globalConfig.url}>
              <h1 className="mr-4 text-lg font-medium text-black mb-8 hover:!text-dark-purple">NETIZENS.ai</h1>
            </a>
          </div>
          <div className="mb-4 pb-0 border-b border-[#D2CEFF]">
            <h2 className="text-sm font-semibold text-text-primary mb-2">Character management</h2>
            <ul className="space-y-1 pl-2 mb-2">
              {menuItems.slice(0, 2).map((item) => (
                <SidebarItem key={item.label} {...item} onClick={toggleMenuEvent} />
              ))}
            </ul>
          </div>

          <div className="mb-4 pb-0 border-b border-[#D2CEFF]">
            <h2 className="text-sm font-semibold text-text-primary mb-2">Assets</h2>
            <ul className="space-y-1 pl-2 mb-2">
              {menuItems.slice(2, 4).map((item) => (
                <SidebarItem key={item.label} {...item} onClick={toggleMenuEvent} />
              ))}
            </ul>
          </div>
          
          <div className="mb-3 pb-0 border-b border-[#D2CEFF]">
            <h2 className="text-sm font-semibold text-text-primary mb-2">Content generation</h2>
            <ul className="space-y-1 pl-1 mb-2">
              {menuItems.slice(4, 7).map((item) => (
                <SidebarItem key={item.label} {...item} onClick={toggleMenuEvent} />
              ))}
            </ul>
          </div>

          <div className="mb-3 pb-0">
            <ul className="space-y-1 pl-1">
              {menuItems.slice(7).map((item) => (
                <SidebarItem key={item.label} {...item} onClick={toggleMenuEvent} />
              ))}
            </ul>
          </div>
          
          <div className="mt-auto">
            <ul className="space-y-1 pl-1">
              <SidebarItem icon={Settings} label="Account Settings" to="/settings" onClick={toggleMenuEvent} />
              <SidebarItem icon={HelpCircle} label="Feature requests" to="/feedback" onClick={toggleMenuEvent} />
            </ul>
          </div>
          
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center cursor-pointer hover:text-dark-purple"
              onClick={()=>{
                toggleMenuEvent();
                window.location.href = '/settings'
              }}
            >
              <div className="w-8 h-8 p-[18px] bg-dark-purple rounded-full mr-2 flex items-center justify-center border-radius-50 text-white">
                  {user?.name.slice(0, 2).toUpperCase()}
              </div>
              <div className="flex flex-col space-y-0">
                <div className="text-sm font-semibold">{user?.name}</div>
                <div className="text-xs text-gray-500">{user?.email}</div>
              </div>
            </div>
            <button className="hover:bg-outline-gray text-black p-1 rounded-md transition-colors duration-200" onClick={onLogout}>
              <LogOut size={18} />
            </button>
            <div className="w-8"></div>
          </div>
       </div>
     </div>
  );
};

  

export default SidebarMobile;